/* eslint-disable jsx-a11y/alt-text */

/* eslint-disable @next/next/no-img-element */

/**
 * NOTE: `priority` works only in chromium browsers. This is caused because
 * Firefox-based and other browsers ignores manual preloads.
 * Reference: https://www.builder.io/blog/code-prefetching-is-a-lie
 */

/**
 * Browsers that do not support responsive images preload may
 * request the image twice if the image has `priority: true`.
 * They will try to initially preload the `src` image and then,
 * if there is responsive images on `srcSet`, it will request the responsive one
 * See:
 *  - https://caniuse.com/?search=imagesizes
 *  - https://caniuse.com/?search=imagesrcset
 * The only relevant browser that seems to not support these
 * is Safari, which is a decent tradeoff.
 * This component aims to have a similar basic API compared to
 * https://nextjs.org/docs/api-reference/next/image so we can transition from it
 * to next/image if needed in the future.
 */
import Head from 'next/head';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { twJoin } from 'tailwind-merge';

import { removeAllWhiteSpaceChars } from 'lib/string';

const Image = forwardRef(
  (
    {
      className,
      grayscale = false,
      loading = 'lazy',
      priority = false,
      sizes = null,
      src,
      srcSet = null,
      ...rest
    },
    ref
  ) => {
    /**
     * We normally use strings full of white spaces on these props
     * for making it easier to read. Here we remove it so its correctly
     * written on the HTML.
     */
    const normalizedSrcSet = srcSet
      ? removeAllWhiteSpaceChars(srcSet).trim()
      : null;
    const normalizedSrcSizes = sizes
      ? removeAllWhiteSpaceChars(sizes).trim()
      : null;

    return (
      <>
        {priority && (
          <Head>
            <link
              /**
               * `key` is a unique prop from the Next.js API.
               * It is used on components inside `Head` to skip
               * possible duplicates, so we don't preload the same image
               * twice if the image is used and preloaded more than once.
               * See: https://nextjs.org/docs/api-reference/next/head
               */
              key={srcSet && sizes ? normalizedSrcSet : src}
              as="image"
              fetchpriority="high"
              href={srcSet && sizes ? null : src}
              imagesizes={normalizedSrcSizes ? normalizedSrcSizes : null}
              imagesrcset={normalizedSrcSet ? normalizedSrcSet : null}
              rel="preload"
            />
          </Head>
        )}
        <img
          ref={ref}
          className={twJoin(grayscale && 'grayscale', className)}
          decoding="async"
          fetchpriority={priority ? 'high' : 'low'}
          loading={priority ? null : loading}
          sizes={normalizedSrcSizes}
          src={src}
          srcSet={normalizedSrcSet}
          {...rest}
        />
      </>
    );
  }
);

Image.displayName = 'Image';

Image.propTypes = {
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  grayscale: PropTypes.bool,
  loading: PropTypes.oneOf(['eager', 'lazy']),
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string,
  sizes: PropTypes.string,
  priority: PropTypes.bool,
};

export default Image;
