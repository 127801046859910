import PropTypes from 'prop-types';
import { twJoin } from 'tailwind-merge';

import Badge from 'shopper/components/Badge';
import Icon from 'shopper/components/Icon';
import { TabNavItem } from 'shopper/components/TabNav';
import Text from 'shopper/components/Text';

import { sendEvent } from 'lib/gtag';

const GA_EVENT_CATEGORY = 'navbar';

const MobileNavbarTabNavItem = ({
  gaEventAction,
  href = null,
  iconName,
  isActive = false,
  title,
  withCounter,
  onClick,
}) => (
  <TabNavItem
    active={isActive}
    as="button"
    className={twJoin(
      'relative flex h-full flex-1 flex-col items-center justify-start px-0 pt-4 text-center text-neutral-high-400 hover:shadow-none [&>span]:flex [&>span]:max-h-5 [&>span]:flex-col',
      isActive && 'shadow-none focus:shadow-none dark:shadow-none'
    )}
    onClick={() => {
      if (!href) {
        onClick();
      }

      sendEvent({
        action: gaEventAction,
        category: GA_EVENT_CATEGORY,
      });
    }}
  >
    <div
      className={twJoin(
        'relative [&>svg]:text-neutral-high-400',
        iconName &&
          isActive &&
          '[&>svg]:text-primary-300 dark:[&>svg]:text-primary-300'
      )}
    >
      <Icon name={iconName} size="size2" />
      {withCounter && (
        <Badge
          className="absolute -right-0 -top-1"
          shape="dot"
          type="highlight"
        />
      )}
    </div>
    <Text
      className={twJoin(
        'mt-1 text-neutral-high-500',
        isActive && 'text-primary-300 dark:text-primary-300'
      )}
      size="size3"
      bold
    >
      {title}
    </Text>
  </TabNavItem>
);

MobileNavbarTabNavItem.propType = {
  gaEventAction: PropTypes.string.isRequired,
  href: PropTypes.string,
  iconName: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  title: PropTypes.string.isRequired,
  withCounter: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MobileNavbarTabNavItem;
