import PropTypes from 'prop-types';
import { UIDReset } from 'react-uid';

import ExperimentProvider from './ExperimentProvider';
import GlobalQueriesProvider from './GlobalQueriesProvider';
import LocalWishlistProvider from './LocalWishlistProvider';
import QueryClientProvider from './QueryClientProvider';
import TopbarProvider from './TopbarProvider';
import UserProvider from './UserProvider';
import UtmProvider from './UtmProvider';

const GlobalProvider = ({
  activeOffersListTab,
  categories,
  children,
  featuredLandings,
  experiments = null,
  highlightedLandings,
  stores,
  topbarAd,
}) => (
  <UIDReset>
    <QueryClientProvider>
      <GlobalQueriesProvider
        activeOffersListTabName={activeOffersListTab}
        categories={categories}
        featuredLandings={featuredLandings}
        highlightedLandings={highlightedLandings}
        stores={stores}
        topbarAd={topbarAd}
      >
        <LocalWishlistProvider>
          <ExperimentProvider ssrActiveExperiments={experiments}>
            <TopbarProvider isTopbarAdActive={!!topbarAd}>
              <UserProvider>
                <UtmProvider>{children}</UtmProvider>
              </UserProvider>
            </TopbarProvider>
          </ExperimentProvider>
        </LocalWishlistProvider>
      </GlobalQueriesProvider>
    </QueryClientProvider>
  </UIDReset>
);

GlobalProvider.propTypes = {
  activeOffersListTab: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.shape()),
  children: PropTypes.node.isRequired,
  featuredLandings: PropTypes.shape({
    landings: PropTypes.arrayOf(PropTypes.shape()),
  }),
  experiments: PropTypes.shape(),
  highlightedLandings: PropTypes.shape({
    landings: PropTypes.arrayOf(PropTypes.shape()),
  }),
  stores: PropTypes.arrayOf(PropTypes.shape()),
  topbarAd: PropTypes.shape({
    imageSrc: PropTypes.string,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    isClosable: PropTypes.bool.isRequired,
    bgcolor: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
};

export default GlobalProvider;
