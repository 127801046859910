/* eslint-disable @next/next/no-img-element */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import AvatarBadge from './AvatarBadge';
import Counter from './Counter';

import APP from 'constants/app';

// TODO: min-Y should be equal spacing to size-Y
const containerAvatarSizeStyles = {
  size1: 'size-18 min-w-18',
  size2: 'size-16 min-w-16',
  size3: 'size-14 min-w-14',
  size4: 'size-10 min-w-10',
  size5: 'size-8 min-w-8',
  size6: 'size-6 min-w-6',
};

const iconStyles = {
  user: 'bg-secondary-400',
  store: 'bg-primary-500',
  coupon: 'bg-info-500',
};

const imgSizes = {
  size1: {
    height: 72,
    width: 72,
  },
  size2: {
    height: 64,
    width: 64,
  },
  size3: {
    height: 56,
    width: 56,
  },
  size4: {
    height: 40,
    width: 40,
  },
  size5: {
    height: 32,
    width: 32,
  },
  size6: {
    height: 24,
    width: 24,
  },
};

export const ICONS = {
  coupon: {
    alt: 'Cupom',
    src: `${APP.LOCAL_IMAGE_PATH}/features/avatar/coupon.svg`,
  },
  store: {
    alt: 'Loja',
    src: `${APP.LOCAL_IMAGE_PATH}/features/avatar/store.svg`,
  },
  user: {
    alt: 'Usuário',
    src: `${APP.LOCAL_IMAGE_PATH}/features/avatar/user.svg`,
  },
};

const iconsSizes = {
  size1: 40,
  size2: 40,
  size3: 40,
  size4: 24,
  size5: 16,
  size6: 16,
};

const getComponentForIcon = ({ icon, iconComponent, size }) => {
  if (iconComponent) {
    return iconComponent;
  }

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      className="block rounded-full align-top"
      {...(ICONS[icon] ?? ICONS.user)}
      height={iconsSizes[size]}
      width={iconsSizes[size]}
    />
  );
};

const Avatar = ({
  admin = false,
  className,
  counter = null,
  description = null,
  grayscale = false,
  icon = 'user',
  iconComponent = null,
  level = null,
  loading = null,
  size = 'size1',
  sizes = null,
  src = null,
  srcSet = null,
  ...rest
}) => {
  const Icon = getComponentForIcon({ icon, iconComponent, size });
  const [avatarSrc, setAvatarSrc] = useState(src || srcSet);

  const hasCounter = counter > 0;
  const shouldRenderBadge =
    level !== null && level >= 0 && icon === 'user' && size !== 'size6';

  return (
    <div
      className={twMerge(
        'relative block h-fit select-none',
        containerAvatarSizeStyles[size],
        className
      )}
      {...rest}
    >
      {hasCounter && (
        <Counter
          className={twJoin('relative', containerAvatarSizeStyles[size])}
          count={counter}
          size={size}
        />
      )}
      {!hasCounter && (
        <div
          className={twJoin(
            'relative flex h-full flex-col items-center justify-center rounded-full border border-solid border-neutral-high-400',
            iconComponent && containerAvatarSizeStyles[size],
            iconStyles[icon]
          )}
        >
          {avatarSrc ? (
            <img
              alt={description}
              className={twJoin(
                'block rounded-full align-top',
                grayscale && 'grayscale'
              )}
              loading={loading}
              sizes={sizes}
              src={src}
              srcSet={srcSet}
              onError={() => setAvatarSrc(null)}
              {...imgSizes[size]}
            />
          ) : (
            Icon
          )}
        </div>
      )}
      {shouldRenderBadge && (
        <AvatarBadge admin={admin} level={level} size={size} />
      )}
    </div>
  );
};

Avatar.ICONS = Object.keys(ICONS);

Avatar.propTypes = {
  /**
   * If `true` renders a admin badge background if the `level`
   * prop is present and `icon` prop equals `user`
   */
  admin: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * Renders a number when there is no `src` prop present
   */
  counter: PropTypes.number,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Renders the number of authors of an offer
   */
  authorCount: PropTypes.number,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Text containing some descriptive information about the image rendered
   */
  description: PropTypes.string,
  /**
   * Renders the image with a grayscale filter
   */
  grayscale: PropTypes.bool,
  /**
   * Renders an icon when there is no `src` prop present
   *
   * One of `user`, `store`, `coupon` or an `Icon` type
   */
  icon: PropTypes.oneOfType([
    PropTypes.oneOf([...Avatar.ICONS, PropTypes.shape()]),
  ]),
  /**
   * Custom icon to be used instead of the icons options
   */
  iconComponent: PropTypes.object,
  /**
   * Number to be rendered in badge.
   * If no level is passed, badge wont be rendered.
   * Should be a positive number and less than 100
   */
  // eslint-disable-next-line consistent-return
  level: (props, propName, componentName) => {
    const isWithinValidNumberRange = (number) => number < 0 || number > 99;

    if (
      props[propName] &&
      !Number.isInteger(props[propName]) &&
      isWithinValidNumberRange(props[propName])
    ) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Prop \`${propName}\` should be a positive number and less than 100.`
      );
    }
  },
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Whether to use HTML loading attribute
   */
  loading: PropTypes.oneOf(['eager', 'lazy']),
  /**
   * Renders a determined size
   */
  size: PropTypes.oneOf(['size1', 'size2', 'size3', 'size4', 'size5', 'size6']),
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * A valid `sizes` image attribute to be used alongside `srcSet`
   */
  sizes: PropTypes.string,
  /**
   * Path to a valid image
   */
  src: PropTypes.string,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * A valid `srcSet` image attribute
   */
  srcSet: PropTypes.string,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback function to be executed on click
   */
  onClick: PropTypes.func,
};

export default Avatar;
