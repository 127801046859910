import PropTypes from 'prop-types';
import { createElement, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const HTML_MARKUP_OPTIONS = {
  section: 'section',
  div: 'div',
  form: 'form',
  aside: 'aside',
  nav: 'nav',
  main: 'main',
  footer: 'footer',
  article: 'article',
  /**
   * TODO: has a lot of cases that transform this component into a anchor, but
   * this isn't desirable, so in future we improve it.
   */
  a: 'a',
};

const Box = forwardRef(
  ({ children = null, className, full = false, as = 'div', ...rest }, ref) =>
    createElement(
      HTML_MARKUP_OPTIONS[as] || HTML_MARKUP_OPTIONS.div,
      {
        ref,
        className: twMerge(
          'flex flex-col rounded-2 bg-neutral-high-100 dark:bg-neutral-low-500',
          full && 'w-full',
          className
        ),
        ...rest,
      },
      children
    )
);

Box.displayName = 'Box';

Box.propTypes = {
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * HTML markup to be rendered
   */
  as: PropTypes.oneOf(Object.values(HTML_MARKUP_OPTIONS)),
  /**
   * Components to be rendered inside component
   */
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * Flex fills the parent component's width
   */
  full: PropTypes.bool,
};

export default Box;
