import { get } from 'lib/request';

export const getActiveAdsByStore = async ({ storeSlug }) => {
  const {
    data: {
      storeAd: [storeAd = null],
    },
  } = await get(`/ads/store/${storeSlug}`);

  return storeAd;
};

getActiveAdsByStore.fallback = null;

export const getAds = async ({ type } = {}) =>
  get(`/ads${type ? `?type=${type}` : ''}`);

getAds.fallback = {
  data: { cardAd: [], smallAd: [], sidebarAd: [], topAd: [], storeFullAd: [] },
};

export const getTopbarAd = async (opts) => {
  const {
    data: {
      topAd: [topbarAd = null],
    },
  } = await get(`/ads?type=TOP_AD`, opts);

  return topbarAd;
};

getTopbarAd.fallback = null;

export const getSearchAd = async (opts) => {
  const {
    data: { sponsorAd = [] },
  } = await get(`/ads?type=SPONSOR_AD`, opts);

  return sponsorAd;
};
