import { appendQsParams, get, post } from 'lib/request';

export const getStoreAutocompleteKeywords = async ({ query }, opts) =>
  get(`/store/autocomplete${query ? `?query=${query}` : ''}`, opts);

export const getStoreCouponsList = async ({ limit, page, storeSlug }, opts) =>
  get(appendQsParams(`/stores/${storeSlug}/coupons`, { page, limit }), opts);

export const getStoresList = async ({ from } = {}, opts) =>
  get(`/store${from ? `?from=${from}` : ''}`, opts);

getStoresList.fallback = [];

export const getStoreInfo = async ({ storeSlug }) =>
  get(`/stores/${storeSlug}`);

export const getPopularStoresListWithOffersCount = async (limit) =>
  get(`/offer/stores/popular${limit ? `?limit=${limit}` : ''}`);

getPopularStoresListWithOffersCount.fallback = [];

export const getStoreOffersList = async (
  { storeSlug, after, limit, sort },
  opts
) =>
  get(
    appendQsParams(`/stores/${storeSlug}/offers`, { after, limit, sort }),
    opts
  );

export const postStoreRating = async ({ storeSlug, rating }) =>
  post(`/stores/${storeSlug}/rating`, { body: { rating } });
