const LOGIN = {
  AFTER_LOGIN_REDIRECT_QS: 'lcb',
  AUTHENTICATION_UNVERIFIED: 'Esta conta ainda não foi verificada',
  ACCOUNT_IS_DISABLED: 'Esta conta foi desabilitada pelo usuário',
  AUTHENTICATION_FAILED: 'Senha ou e-mail inválido',
  EMAIL_ALREADY_REGISTERED: 'Este e-mail já foi cadastrado',
  EMAIL_INFO:
    'Informe o seu e-mail para receber o link de recuperação de senha',
  EMAIL_NOT_VALID: 'Digite um endereço de e-mail válido',
  EMAIL_NOT_VERIFIED: 'Verificação de endereço de e-mail pendente',
  EMAIL_REQUIRED: 'Digite seu e-mail',
  FIELD_REQUIRED: 'Esse campo precisa ser preenchido',
  MODAL_HEADING: 'Descubra ofertas incríveis!',
  NAME_NOT_VALID: 'Seu nome contém caracteres inválidos',
  NAME_REQUIRED: 'Digite seu nome',
  PASSWORD_CONFIRMATION_NOT_EQUAL:
    'Este campo precisa ser o mesmo que a nova senha',
  PASSWORD_EMAIL: 'E-mail de recuperação enviado!',
  PASSWORD_INSTRUCTIONS:
    'Acabamos de enviar um email de recuperação. Clique no link recebido para acessar sua conta.',
  PASSWORD_RECOMMENDATIONS:
    'Sua nova senha precisa ter no mínimo 6 caracteres e ser diferente da sua senha anterior.',
  PASSWORD_RECOVERY_QUESTION: 'Esqueci a senha',
  PASSWORD_TOO_SHORT: 'Sua senha deve conter 6 ou mais caracteres',
  PHONE_NOT_VALID: 'Digite um telefone válido',
  ERRORS: {
    EMAIL_REQUIRED: 'email is required',
    EMAIL_INVALID: 'email is not valid',
    EMAIL_EXISTS: 'email is already being used',
    NAME_REQUIRED: 'name is required',
    PASSWORD_REQUIRED: 'password is required',
    PASSWORD_TOO_SHORT: 'password is too short',
  },
};

export default LOGIN;
