import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { createContext, useContext, useMemo } from 'react';

import {
  landingQueryKeys,
  offerQueryKeys,
  storeQueryKeys,
  topbarQueryKeys,
} from 'lib/queryKeys';
import { isBrowser } from 'lib/utils';

import { getTopbarAd } from 'services/ads';
import { getCategoriesList } from 'services/category';
import { getLandingsList } from 'services/landing';
import { getStoresList } from 'services/store';

import HOME_OFFERS_LIST_TABS from 'constants/homeOffersListTabs';

const NEXTJS_SERVER_SIDE_PAGE = '__N_SSP';
const SHARED_QUERY_CONFIG = {
  staleTime: Infinity,
  refetchOnWindowFocus: false,
};

const GlobalQueriesContext = createContext({
  activeOffersListTab: null,
});

const GlobalQueriesProvider = ({
  activeOffersListTabName,
  children,
  topbarAd,
  stores,
  categories,
  featuredLandings,
  highlightedLandings,
}) => {
  const router = useRouter();

  /**
   * Throughout the project, we have a lot of important components that consumes
   * these global queries. And some of these components can easily negatively
   * impacts performance metrics, like `<TopbarAd />`. So, to avoid possible
   * issues when server-side request returns "empty", we don't fetch the data
   * in client-side, turning this feature exclusively to client-side pages. The
   * unique page that isn't included in this condition is the search-page, that
   * is a false server-page. In server-side of search-page is used only to send
   * specific server data to Supix.
   *
   * The better way to handle with this conditions, is to determine if the
   * current route is a server-side or client-side page. But, in Next.JS v12
   * doesn't exists nothing built-in for that. The way that we found to
   * determine it is to use internal props of next-router that aren't very well
   * documented and because of that, can cause breaking-changes when we
   * upgrading to the next Next.JS major (>=13).
   */
  const isServerPage =
    isBrowser() &&
    !!(
      router.pathname !== '/buscar' &&
      router.components[router.pathname][NEXTJS_SERVER_SIDE_PAGE]
    );

  useQuery({
    queryKey: offerQueryKeys.categories({ includeOfferSelections: true }),
    queryFn: ({ signal }) =>
      getCategoriesList({ selectedCategories: true }, { signal }),
    initialData: categories,
    refetchOnMount: (!categories || !isServerPage) && 'always',
    enabled: !categories,
    ...SHARED_QUERY_CONFIG,
  });

  useQuery({
    queryKey: landingQueryKeys.highlighted(),
    queryFn: ({ signal }) => getLandingsList({ highlighted: true }, { signal }),
    initialData: highlightedLandings,
    refetchOnMount: (!highlightedLandings || !isServerPage) && 'always',
    enabled: !highlightedLandings,
    ...SHARED_QUERY_CONFIG,
  });

  useQuery({
    queryKey: landingQueryKeys.featured(),
    queryFn: ({ signal }) => getLandingsList({ featured: true }, { signal }),
    initialData: featuredLandings,
    refetchOnMount: (!featuredLandings || !isServerPage) && 'always',
    enabled: !featuredLandings,
    ...SHARED_QUERY_CONFIG,
  });

  useQuery({
    queryKey: storeQueryKeys.list({ featured: true }),
    queryFn: ({ signal }) => getStoresList({ from: 'topbar' }, { signal }),
    initialData: stores,
    refetchOnMount: (!stores || !isServerPage) && 'always',
    enabled: !stores,
    ...SHARED_QUERY_CONFIG,
  });

  useQuery({
    queryKey: topbarQueryKeys.ads(),
    queryFn: ({ signal }) => getTopbarAd({ signal }),
    initialData: topbarAd,
    refetchOnMount: (!topbarAd || !isServerPage) && 'always',
    cacheTime: Infinity,
    enabled: !topbarAd,
    ...SHARED_QUERY_CONFIG,
  });

  const activeOffersListTab = useMemo(() => {
    if (!activeOffersListTabName) {
      return null;
    }

    return (
      HOME_OFFERS_LIST_TABS.find(({ id }) => id === activeOffersListTabName) ||
      null
    );
  }, [activeOffersListTabName]);

  return (
    <GlobalQueriesContext.Provider value={{ activeOffersListTab }}>
      {children}
    </GlobalQueriesContext.Provider>
  );
};

export const useGlobalQueries = () => useContext(GlobalQueriesContext);

export default GlobalQueriesProvider;
