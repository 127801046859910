/**
 * To debug re-renders with why-did-you-render library, uncomment this import
 * and disable Prettier vscode extension (this import should be first of all)
 */
// import 'lib/whyDidYouRender';
import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import GlobalLayout from 'components/Layout/Global';
import GlobalScripts from 'components/Scripts/GlobalScripts';

import { sendWebVitalsToGoogleAnalytics } from 'lib/gtag';

import GlobalProvider from 'providers/GlobalProvider';

import APP from 'constants/app';

import 'style/globals.css';

const WEB_VITAL_METRIC_LABEL = 'web-vital';

export function reportWebVitals(metric) {
  if (metric.label === WEB_VITAL_METRIC_LABEL) {
    sendWebVitalsToGoogleAnalytics(metric);
  }
}

const PromobitApp = ({ Component, pageProps }) => {
  const router = useRouter();

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    const cachedScrollPositions = [];
    let shouldScrollRestore = false;

    const restoreScroll = () => {
      if (!shouldScrollRestore) {
        return;
      }

      const { x, y } = shouldScrollRestore;
      window.scrollTo(x, y);
      shouldScrollRestore = false;
    };

    const cacheScrollPosition = () => {
      cachedScrollPositions.push([window.scrollX, window.scrollY]);
    };

    router.events.on('routeChangeStart', cacheScrollPosition);
    router.events.on('routeChangeComplete', restoreScroll);

    router.beforePopState(() => {
      if (cachedScrollPositions.length > 0) {
        const [x, y] = cachedScrollPositions.pop();
        shouldScrollRestore = { x, y };
      }

      return true;
    });

    return () => {
      router.events.off('routeChangeStart', cacheScrollPosition);
      router.events.off('routeChangeComplete', restoreScroll);
    };
  }, []);

  return (
    <GlobalProvider {...pageProps[APP.CTX_OPTIONAL_PROPS]}>
      <GlobalScripts />
      <GlobalLayout
        isRecoveryPasswordValid={
          !!pageProps?.recoveryPassword && pageProps.recoveryPassword?.isValid
        }
      >
        <Head>
          {/*
            Customize `viewport` meta-tag to avoid auto zoom in input when using
            iPhone devices
          */}
          <meta
            content="minimum-scale=1, width=device-width, initial-scale=1.0, maximum-scale=1.0, viewport-fit=cover, shrink-to-fit=no"
            name="viewport"
          />
        </Head>
        <Component {...pageProps} />
      </GlobalLayout>
    </GlobalProvider>
  );
};

PromobitApp.propTypes = {
  pageProps: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    categories: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    stores: PropTypes.array,
    topbarAd: PropTypes.shape({
      imageSrc: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      isClosable: PropTypes.bool.isRequired,
      bgColor: PropTypes.string.isRequired,
    }),
  }),
};

export default PromobitApp;
