import { getCookie } from 'cookies-next';

import { getLegacyUserSession, getUserProfileInfo } from 'services/user';

import {
  getFromLocalStorage,
  removeFromLocalStorage,
  setLocalStorage,
} from './localStorage';

import COOKIES from 'constants/cookies';
import LOCAL_STORAGE from 'constants/localStorage';
import USER from 'constants/user';

const USER_LOCAL_STORAGE_CACHE = 1000 * 60 * 120; // 2 hours

export const clearLoginStorage = async () => {
  const { deleteCookie } = await import('cookies-next');

  removeFromLocalStorage(LOCAL_STORAGE.ACCESS_TOKEN);
  removeFromLocalStorage(LOCAL_STORAGE.LOGGED_IN_USER);
  removeFromLocalStorage(LOCAL_STORAGE.DEPRECATED_USER_PHOTO);
  removeFromLocalStorage(LOCAL_STORAGE.IS_SUPIX_COLLECTED);
  removeFromLocalStorage(LOCAL_STORAGE.SHOWED_WELCOME_NOTIFICATION);
  removeFromLocalStorage(LOCAL_STORAGE.WEBPUSH_HASH);
  deleteCookie(COOKIES.IS_USER_LOGGED);
};

export const filterUsersByWord = (users, word) => {
  if (!word || word.length === 0) {
    return users;
  }

  return users.filter(({ userUsername }) =>
    userUsername?.toLowerCase().includes(word)
  );
};

const getLoggedInUserFromServer = async ({ skipOldApiWorkaround }) => {
  try {
    /**
     * This is a workaround for creating a session for the old web platform
     */
    if (!skipOldApiWorkaround) {
      getLegacyUserSession({ token: null }, { ignoreErrors: true });
    }

    const userProfile = await getUserProfileInfo();

    if (userProfile) {
      setLoggedInUserToLocalStorage(userProfile);

      const LoggedInUser = (await import('lib/LoggedInUser')).default;

      return new LoggedInUser(userProfile);
    }
  } catch {
    setLocalStorage(LOCAL_STORAGE.LOGGED_IN_USER, null);
    return null;
  }
};

export const getLoggedInUser = async ({ skipOldApiWorkaround, token }) => {
  const isUserLogged = getCookie(COOKIES.IS_USER_LOGGED);

  if (!isUserLogged) {
    return null;
  }

  if (!skipOldApiWorkaround) {
    /**
     * This is a workaround for getting an existing session from the old
     * web platform
     */
    const legacyUserSession = await getLegacyUserSession(
      { token },
      { ignoreErrors: true }
    );

    if (legacyUserSession && legacyUserSession.token) {
      setLocalStorage(LOCAL_STORAGE.ACCESS_TOKEN, legacyUserSession.token);

      return getLoggedInUserFromServer({ skipOldApiWorkaround: true });
    }

    return null;
  }

  if (!token) {
    return null;
  }

  const loggedInUser = JSON.parse(
    getFromLocalStorage(LOCAL_STORAGE.LOGGED_IN_USER)
  );

  const isLoggedInUserCacheExpired =
    new Date().getTime() >= loggedInUser?.expireDate;

  if (!!loggedInUser && !isLoggedInUserCacheExpired) {
    const LoggedInUser = (await import('lib/LoggedInUser')).default;

    return new LoggedInUser(loggedInUser);
  }

  return getLoggedInUserFromServer({ skipOldApiWorkaround });
};

/**
 * In URL the userUsername starts with the prefix '@', so we remove this to
 * be used in specific cases like request params
 */
export const getUserUsernameWithoutPrefix = (userUsername) =>
  userUsername.substring(1);

export const isUserAdmin = (userTypeName) => userTypeName === USER.TYPES.ADMIN;

export const isGenieUser = (userId) => userId === 0;

export const setLoggedInUserToLocalStorage = (profile) => {
  setLocalStorage(
    LOCAL_STORAGE.LOGGED_IN_USER,
    JSON.stringify({
      ...profile,
      expireDate: new Date().getTime() + USER_LOCAL_STORAGE_CACHE,
    })
  );
};

export const toUsernameWithAtSign = (username) =>
  username ? `@${username}` : null;
