import { useQuery } from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import AutoComplete from 'shopper/components/AutoComplete';
import Icon from 'shopper/components/Icon';
import SearchInput from 'shopper/components/SearchInput';

import Image from 'components/Image';

import { stripDuplicates } from 'lib/array';
import { sendEvent } from 'lib/gtag';
import { toCleanedSearchResultsPage } from 'lib/links';
import { getFromLocalStorage, setLocalStorage } from 'lib/localStorage';
import placeholder from 'lib/placeholder';
import { searchQueryKeys } from 'lib/queryKeys';
import { debounce } from 'lib/utils';

import { getSearchAd } from 'services/ads';
import {
  getSearchAutocompleteKeywords,
  getSuggestedSearchKeywordsList,
} from 'services/search';

import { SEARCH_RESULTS_TYPES } from 'constants/searchResults';
import TOPBAR from 'constants/topbar';

const Badge = dynamic(() => import('shopper/components/Badge'));

const MAX_INITIAL_DATA_LENGTH = 4;
const AUTOCOMPLETE_INITIALIZATION_LENGTH = 3;
const MIN_SEARCH_VALUE_TO_SEND = 2;
const RECENT_SEARCHES_LIMIT = 4;

const promobitRecentSearch = JSON.parse(
  getFromLocalStorage('promobit.recent-search')
);

const localRecentSearch = promobitRecentSearch
  ? stripDuplicates([...promobitRecentSearch.splice(-RECENT_SEARCHES_LIMIT)])
  : [];

const writeRecentSearchOnLocalStorage = async (value, searchAds) => {
  if (localRecentSearch.length > 4 - searchAds.length) {
    localRecentSearch.splice(-RECENT_SEARCHES_LIMIT);
  }

  if (localRecentSearch.length === 4 - searchAds.length) {
    localRecentSearch.shift();
  }

  if (localRecentSearch.includes(value)) {
    return;
  }

  setLocalStorage(
    'promobit.recent-search',
    JSON.stringify([...localRecentSearch, value])
  );
};

const getRecentSearchAsAutoCompleteData = (searchAds) => {
  if (localRecentSearch.length === 0) {
    return [...searchAds];
  }

  const recentSearches = [
    ...localRecentSearch,
    ...searchAds.map(({ text }) => text),
  ];

  const limitedRecentSearches =
    searchAds.length > 0
      ? recentSearches.slice(0, MAX_INITIAL_DATA_LENGTH + searchAds.length)
      : recentSearches.slice(0, MAX_INITIAL_DATA_LENGTH);

  return limitedRecentSearches.reverse().map((recentSearch) => {
    const isRecentSearchWordOnSponsoredAds = searchAds.some(
      ({ text: sponsoredAdText }) => sponsoredAdText === recentSearch
    );

    if (isRecentSearchWordOnSponsoredAds) {
      return searchAds.find((sponsoredAd) => sponsoredAd.text === recentSearch);
    }

    return {
      icon: <Icon name="clock" />,
      text: recentSearch,
      value: recentSearch,
      sponsored: false,
    };
  });
};

const TopbarSearch = ({
  className,
  gaEventCategory = TOPBAR.GA_EVENT_CATEGORY,
  id,
  searchResultsType = SEARCH_RESULTS_TYPES.GENERAL,
  withTrends = true,
}) => {
  const router = useRouter();
  const [inputValue, setInputValue] = useState('');
  const [hasSearchBeenOpened, setHasSearchBeenOpened] = useState(false);

  const { data: searchTrends = [] } = useQuery({
    queryKey: searchQueryKeys.trendsList(),
    queryFn: ({ signal }) =>
      getSuggestedSearchKeywordsList(undefined, { signal }),
    enabled: withTrends,
    staleTime: Infinity,
    select: (trendSuggestions = []) =>
      trendSuggestions.slice(0, MAX_INITIAL_DATA_LENGTH).map((suggestion) => ({
        icon: <Icon name="search" />,
        text: suggestion,
        value: suggestion,
      })),
  });

  const {
    data: autoCompleteData,
    refetch,
    isInitialLoading,
  } = useQuery({
    queryKey: searchQueryKeys.autoCompleteValue({ value: inputValue.trim() }),
    queryFn: ({ signal }) =>
      getSearchAutocompleteKeywords({ query: inputValue.trim() }, { signal }),
    enabled: false,
    cacheTime: 60 * 60 * 60, // 1min
    initialData: [],
    keepPreviousData: true,
    staleTime: Infinity,
  });

  const { data: searchAds = [] } = useQuery({
    queryKey: searchQueryKeys.ads(),
    queryFn: ({ signal }) => getSearchAd({ signal }, undefined),
    enabled: hasSearchBeenOpened,
    select: (sponsorAds = []) => {
      const currSponsorAds = sponsorAds;

      return currSponsorAds
        .sort((a, b) => b.position - a.position)
        .map(({ description, image, longUrl }) => ({
          icon: (
            <Image
              alt={description}
              className="size-6 rounded-full"
              height={50}
              src={image}
              width={50}
            />
          ),
          text: description,
          value: description,
          href: longUrl,
          sponsored: true,
        }));
    },
  });

  const recentSearched = getRecentSearchAsAutoCompleteData(searchAds);

  useEffect(() => {
    const searchQuery = router.query?.q;

    setInputValue(searchQuery?.toLowerCase() ?? '');
  }, [router.query]);

  const debouncedRefetch = useCallback(
    debounce(() => {
      refetch();
    }, 300),
    []
  );

  const onChange = useCallback(({ target: { value } }) => {
    setInputValue(value);

    if (value.length >= AUTOCOMPLETE_INITIALIZATION_LENGTH) {
      debouncedRefetch();
    }
  }, []);

  const onCleanup = () => {
    setInputValue('');
    sendEvent({
      action: 'input_search_delete',
      category: gaEventCategory,
    });
  };

  const onFocus = () => {
    if (!hasSearchBeenOpened) {
      setHasSearchBeenOpened(true);
    }
    sendEvent({ action: 'search', category: gaEventCategory });
  };

  const onSend = useCallback(
    async (value) => {
      if (value.length < MIN_SEARCH_VALUE_TO_SEND) {
        return;
      }

      const sponsoredAdByValue = searchAds.find((ad) => ad.value === value);

      if (sponsoredAdByValue) {
        if (sponsoredAdByValue.href) {
          location.replace(sponsoredAdByValue.href);
          return;
        }

        return toCleanedSearchResultsPage(searchResultsType.PARAM, value);
      }

      const isSearchedRecently = recentSearched.find(
        ({ text }) => text === value
      );

      if (!isSearchedRecently) {
        await writeRecentSearchOnLocalStorage(value, searchAds);
      }

      toCleanedSearchResultsPage(searchResultsType.PARAM, value);
    },
    [searchResultsType, recentSearched]
  );

  const onSelectListItem = async (value) => {
    if (inputValue.length > 0) {
      sendEvent({
        action: 'search',
        category: 'autocomplete_click',
        data: value,
      });
      onSend(value);
      return;
    }

    if (
      inputValue.length === 0 &&
      searchTrends?.find(({ text }) => text === value)
    ) {
      const valueIndex = searchTrends.findIndex(({ text }) => text === value);

      sendEvent({
        action: `suggestion_search_recents_${valueIndex}`,
        category: gaEventCategory,
      });
      onSend(value);
      return;
    }

    sendEvent({
      action: 'recents_search_click',
      category: gaEventCategory,
      data: value,
    });
    onSend(value);
  };

  const recentSearchedOptions = recentSearched.filter(({ text }) =>
    searchTrends?.every(({ text: searchTrendText }) => searchTrendText !== text)
  );

  const itemsToAutoComplete =
    inputValue.length < 3
      ? [
          ['BUSCAS RECENTES', recentSearchedOptions],
          ['PRODUTOS MAIS BUSCADOS', searchTrends],
        ]
      : autoCompleteData;

  return (
    <AutoComplete.Root
      className={twJoin('flex flex-1 grow-2', className)}
      full
      onSend={onSend}
    >
      <AutoComplete.Input
        as={SearchInput}
        data-test-selector="searchbar"
        id={id}
        placeholder={placeholder('inputs.placeholders.searchOffer')}
        value={inputValue}
        onChange={onChange}
        onCleanup={onCleanup}
        onFocus={onFocus}
      />
      <AutoComplete.List
        items={itemsToAutoComplete}
        loading={isInitialLoading}
        overlay
      >
        {(items, title) => {
          const list = items.map(
            ({ control, key, icon, sponsored = false }) => (
              <AutoComplete.ListItem
                key={key}
                badge={sponsored && <Badge type="warning">Patrocinado</Badge>}
                control={control}
                iconLeft={icon}
                onSelect={onSelectListItem}
              />
            )
          );

          if (!title) {
            return list;
          }

          return (
            <AutoComplete.Section key={title} title={title}>
              {list}
            </AutoComplete.Section>
          );
        }}
      </AutoComplete.List>
    </AutoComplete.Root>
  );
};

TopbarSearch.propTypes = {
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  gaEventCategory: PropTypes.string,
  id: PropTypes.string,
  searchResultsType: PropTypes.shape({
    NAME: PropTypes.string.isRequired,
    QUERY: PropTypes.string.isRequired,
    TYPE: PropTypes.string,
    BUTTON_TEXT: PropTypes.string,
    GA_EVENT_ACTION_PREFIX: PropTypes.string,
    GENERAL_RESULTS_LIMIT: PropTypes.number.isRequired,
    SORT_OPTIONS: PropTypes.arrayOf(
      PropTypes.shape({
        isDefaultOption: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
  }),
  withTrends: PropTypes.bool,
};

export default memo(TopbarSearch);
